<template>
  <div class="main">
    <div class="box justify center mb48">
      <div class="search box center">
        <el-input v-model="searchTxt" placeholder="请输入资料名称" suffix-icon="el-icon-search"></el-input>
        <el-button @click="searchData">查询</el-button>
        <el-button @click="resetData">重置</el-button>
      </div>
      <el-button @click="showWin" :class="!myUpload ? 'disable_icon' : ''"><span class="iconfont iconjiahao"></span><span>上传资料</span></el-button>
      <!-- <el-button @click="dialogVisible=true"><span class="iconfont iconjiahao"></span><span>上传资料</span></el-button> -->
    </div>
    <el-table :row-class-name="tableRowClassName" :data="tableData" tooltip-effect="dark" style="width: 100%" v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
      <el-table-column align="center" prop="originalName" label="桥梁资料"></el-table-column>
      <el-table-column align="center" prop="timeO" label="上传时间">
        <template slot-scope="scope">{{ scope.row.timeO }}</template>
      </el-table-column>
      <el-table-column align="center" width="180px" label-class-name="option-btn" prop="id" label="操作">
        <template slot-scope="scope">
          <!-- <svg @click="downFile(scope.row)" class="icon fs22 operate-txt" aria-hidden="true">
            <use xlink:href="#iconxiazai"></use>
          </svg>
          <svg @click="deleteFile(scope.row)" class="icon fs22 ml24 delete-txt" aria-hidden="true">
            <use xlink:href="#iconshanchu"></use>
          </svg> -->
          <div class="btn-wrapper">
            <span type="text" class="colorText mR20 pointer" size="small" @click="downFile(scope.row)" :class="!myOption ? 'disable_icon' : ''">下载</span>
            <span type="text" class="colorText mR20 pointer" size="small" @click="check(scope.row)" v-if="scope.row.type !== '.docx' && scope.row.type !== '.doc'" :class="!myOption ? 'disable_icon' : ''">查看</span>
            <span type="text" class="colorText pointer" size="small" @click="deleteFile(scope.row)" :class="!myDel ? 'disable_icon' : ''">删除</span>
          </div>
        </template>
      </el-table-column>
      <template slot="empty" class="imgBox">
        <div>
          <img src="../../../assets/images/noData.png" alt="" class="imgver" />
          <span>暂无数据</span>
        </div>
      </template>
    </el-table>
    <common-pagination v-if="tableData.length > 0" class="pageMain" :total="total" :currentPage="currentPage" :pageSize="pageSize" @handleCurrentChange="handleCurrentChange" />
    <el-dialog title="提示" :visible.sync="dialogVisible" width="500px" :before-close="handleClose" top="calc(50vh - 110px)">
      <el-form :model="ruleForm" ref="ruleForm" :rules="rules" label-width="164px">
        <!-- <el-form-item label="文件标题" prop="title">
          <el-input placeholder="请输入文件标题" v-model="ruleForm.title" />
        </el-form-item> -->
        <el-form-item label="文件附件" prop="file_arr">
          <!-- :on-success="successData"  :auto-upload="false"-->
          <el-upload action=" " multiple :limit="5" :file-list="fileListAdd" :on-remove="handleAddRemove" :on-change="handleAddChange" :on-exceed="handleExceed" :auto-upload="false">
            <el-button v-show="!hideUploadAdd" size="small" type="primary" class="select-btn">选择</el-button>
            <span slot="tip" class="el-upload__tip">上传文件不超过50M</span>
          </el-upload>
        </el-form-item>
        <el-form-item class="btns">
          <el-button class="back-btn pointer" @click="back">取消</el-button>
          <el-button class="submit-btn mR30 pointer" @click="submitForm">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="查看文件" :visible.sync="pdfVisible" width="1200px" :before-close="handlePdfClose" custom-class="pdf-class">
      <div class="pdf-wrapper">
        <div class="pdf-title" v-show="!downImgUrl">
          <el-button size="small" type="primary" @click="nextPdfPage('prev')">上一页</el-button>
          {{ currentPdfPage }} / {{ pagePdfCount }}
          <el-button size="small" type="primary" @click="nextPdfPage('next')">下一页</el-button>
        </div>

        <pdf v-show="!downImgUrl" :src="pdfUrl" @num-pages="pagePdfCount = $event" @page-loaded="currentPdfPage = $event" :page="currentPdfPage"></pdf>
        <img v-show="downImgUrl" :src="downImgUrl" alt="" class="down-img" />
        <div class="pdf-btm">
          <span class="sureBtn back-btn pointer mR30" @click="handlePdfClose">关闭</span>
          <!-- <span class="backBtn submit-btn pointer" @click="submitForm">确定</span> -->
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import CommonPagination from "../../../components/Pagination";
import pdf from "vue-pdf";
export default {
  name: "dataManagementList",
  components: {
    CommonPagination,
    pdf
  },
  props: ["importUrl", "listUrl"],
  data() {
    return {
      pagePdfCount: 0,
      currentPdfPage: 1,
      downImgUrl: "",
      numPages: undefined,
      pdfUrl: "",
      pdfVisible: false,
      isTrue: "",
      filename: "",
      arr: [],
      projectId: "",
      pageSize: 10, //
      currentPage: 0,
      total: 0,
      loading: false,
      searchTxt: "",
      dialogVisible: false,
      actionURL: "",
      fileList: [],
      ruleForm: { title: "", file_arr: [] },
      rules: {
        title: [
          { required: true, message: "请输入文件名称", trigger: "blur" },
          { min: 1, max: 20, message: "长度在 1 到 20 个字符", trigger: "blur" }
        ],
        file_arr: [{ type: "array", required: true, message: "请选择文件", trigger: "blur" }]
      },
      tableData: [],
      fileListAdd: [],
      hideUploadAdd: false,
      loadingPage: null
    };
  },
  // 监听,当路由发生变化的时候执行
  watch: {
    $route(to) {
      this.type = to.query.type;
      console.log(this.type);
    }
  },
  mounted() {
    this.actionURL = ``;
    this.type = this.$route.query.type;
    console.log(this.type);

    this.datalList(); //桥梁列表
    this.isTrue = sessionStorage.getItem("isTrue");
  },
  created() {
    this.projectId = sessionStorage.getItem("projectId");
  },
  methods: {
    nextPdfPage(flag) {
      switch (flag) {
        case "next":
          if (this.currentPdfPage >= this.pagePdfCount) {
            this.currentPdfPage = this.pagePdfCount;
            return;
          }
          this.currentPdfPage++;
          break;
        case "prev":
          if (this.currentPdfPage <= 1) {
            this.currentPdfPage = 1;
            return;
          }
          this.currentPdfPage--;
      }
    },
    handlePdfClose() {
      this.pdfVisible = false;
    },
    tableRowClassName({ row, rowIndex }) {
      //条纹变色
      if (rowIndex % 2 == 1) {
        return "hui1";
      } else {
        return "lv1";
      }
    },

    showWin() {
      if (!this.myUpload) {
        this.$message.error("您没有上传权限！");
        return;
      }

      if (!this.isTrue) return;
      this.dialogVisible = true;
      this.hideUploadAdd = false;
    },

    //返回
    back() {
      this.handleClose();
    },

    //查询
    searchData() {
      this.currentPage = 1;
      this.datalList();
    },

    //桥梁列表
    datalList() {
      this.$axios
        .get(`${this.baseURL}${this.listUrl}/${this.currentPage}/${this.pageSize}/${this.projectId}?name=${this.searchTxt}`)
        .then((res) => {
          console.log("资料列表", res);
          this.tableData = res.data.data.records;
          this.total = res.data.data.total;
          // if(this.tableData.length == 0){
          //   if( this.currentPage >1){
          //      this.currentPage =  this.currentPage -1;
          //      this.datalList();
          //   }else{
          //     this.currentPage = 1;
          //      this.datalList();
          //   }
          // }
        })
        .catch((err) => {});
    },

    // 上传change事件
    handleAddChange(file, fileList) {
      // 图片大小限制
      const isLt20M = file.size / 1024 / 1024 < 50;
      if (!isLt20M) {
        this.$message.error("文件太大了!");
        fileList.splice(-1, 1);
      } else {
        this.fileListAdd = fileList;
        this.ruleForm.file_arr = this.fileListAdd;
        this.$refs.ruleForm.validate((valid) => {});
      }
      // 上传文件>=限制个数时隐藏上传组件
      if (fileList.length >= 5) {
        this.hideUploadAdd = true;
      }
    },
    // 多选大于限制个数时做提示
    handleExceed(file, fileList) {
      this.$message({
        message: "上传文件超出限制个数！",
        type: "warning"
      });
    },
    // 移除文件
    handleAddRemove(file, fileList) {
      this.hideUploadAdd = false;
      this.handleAddChange(file, fileList);
    },

    // 确定新增
    submitForm() {
      this.ruleForm.file_arr = this.fileListAdd;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loadingPage = this.$loading({
            lock: true,
            text: "正在加载...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
          });

          let formData = new FormData();
          formData.append("title", this.ruleForm.title);
          this.fileListAdd.map((item) => {
            formData.append("file", item.raw);
          });
          this.$axios.post(`${this.baseURL}${this.importUrl}?bizId=${this.$store.state.projectId}&originalName=${this.ruleForm.title}`, formData).then((res) => {
            this.$message({
              message: "上传成功",
              type: "success"
            });
            this.loadingPage.close();
            this.handleClose();
          });
        }
      });
    },
    handleClose() {
      this.$nextTick(() => {
        this.$refs.ruleForm.resetFields();
        this.fileListAdd = [];
      });
      this.dialogVisible = false;
      this.datalList();
    },

    handleCurrentChange(val) {
      console.log(val);
      this.currentPage = val;
      this.datalList();
    },

    //是否删除
    deleteFile(data) {
      if (!this.myDel) {
        this.$message.error("您没有删除权限！");
        return;
      }

      console.log(data);
      this.$confirm(`<i class="el-icon-warning"></i>确认删除"${data.originalName}"？`, "确认删除", {
        dangerouslyUseHTMLString: true,
        cancelButtonText: "取消",
        confirmButtonText: "确定",
        closeOnClickModal: false,
        cancelButtonClass: "btn-cancel",
        confirmButtonClass: "btn-confirm"
      })
        .then(() => {
          this.httpDel(data.id);
        })
        .catch(function () {});
    },

    httpDel(id) {
      this.$axios.delete(`${this.baseURL}sys/sysFile/${id}`).then((res) => {
        console.log("删除", res);
        this.$message({
          message: res.data.errMsg,
          type: "success",
          duration: 1000
        });
        this.currentPage = 1;
        this.datalList();
        // if (res.data.code === 1) this.getDataList()
      });
    },

    //重置
    resetData() {
      this.handleCurrentChange(1);
      this.currentPage = 1;
      this.searchTxt = "";
      this.datalList();
    },

    // 下载
    downFile(item) {
      if (!this.myOption) {
        this.$message.error("您没有下载权限！");
        return;
      }
      var filename = item.originalName;
      var id = item.id;
      //  let a = document.createElement('a')
      // a.href = `${this.baseURL}sys/sysFile/${id}`;
      // a.click();

      //  this.$axios.get(`${this.baseURL}sys/sysFile/${id}`).then(res => {
      //     console.log('下载',res)
      // })
      // bridge.cloudansys.cn   this.baseURL
      // var a = `${this.downloadURL}sys/sysFile/${id}`;
      // console.log('a',a)

      // 老版本
      // var a = document.createElement('a')
      // a.href = `${this.downloadURL}sys/sysFile/${id}`
      // const agent = window.navigator
      // if (agent.userAgent.match(/QQ/i) || (agent.mimeTypes[0]&&agent.mimeTypes[0].type.match(/360/i))) {
      //   // a.target = '_blank'
      // }
      // a.click()

      let url = `${this.downloadURL}sys/sysFile/${id}`;
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.download = filename; // 文件名
      document.body.appendChild(link);
      link.click();
      console.log("filename", filename);
      document.body.removeChild(link); // 下载完成移除元素

      // link.download = item.fileName;
      // link.href = e.target.result;
      // document.body.appendChild(a);
      // link.click();
    },
    check(row) {
      if (!this.myOption) {
        this.$message.error("您没有查看权限！");
        return;
      }
      let arr = [".png", ".jpg", ".jpeg", ".svg"];
      if (arr.includes(row.type)) {
        this.pdfVisible = true;
        this.downImgUrl = `${this.downloadURL}sys/sysFile/${row.id}`;
        console.log("downImgUrl", this.downImgUrl);
        return;
      }
      // if (row.type == ".docx" || row.type == ".doc") {
      //   var a = document.createElement("a");
      //   a.href = `${this.downloadURL}sys/sysFile/${row.id}`;
      //   a.click();
      //   return;
      // }

      this.pdfVisible = true;
      //  var a = `${this.downloadURL}sys/sysFile/${id}`;
      // console.log('a',a)
      // var a = document.createElement("a");
      this.downImgUrl = "";
      this.currentPdfPage = 1;
      this.pdfUrl = pdf.createLoadingTask(`${this.downloadURL}sys/sysFile/${row.id}`);
      this.pdfUrl.promise.then((pdf) => {
        console.log("numPages", this.numPages);
        this.numPages = pdf.numPages;
      });
      console.log("pafurl", this.pdfUrl);
      // a.href = `${this.downloadURL}sys/sysFile/${id}`;
      // const agent = window.navigator;
      // // if (agent.userAgent.match(/QQ/i) || (agent.mimeTypes[0]&&agent.mimeTypes[0].type.match(/360/i))) {
      // // }
      // a.click();
      // console.log(`${this.downloadURL}sys/sysFile/${id}`, PDFObject);
      // console.log("PDFObject", PDFObject);
      // PDFObject.embed(`../../../assets/pdf/a.pdf`, "#example");
    }
  }
};
</script>

<style scoped lang="scss">
.main ::v-deep .el-dialog__wrapper div.pdf-class {
  width: 1200px !important;
  margin-top: 0px !important;
  max-height: calc(100vh - 8px);
  overflow: auto;
  margin: auto;

  .pdf-wrapper {
    box-sizing: border-box;
    text-align: center;

    img {
      margin: auto;
    }

    .pdf-btm {
      padding-top: 20px;
      text-align: center;
    }

    .pdf-title {
      padding-bottom: 15px;
      text-align: center;
    }
  }
}

.down-img {
  width: 100%;
}

.main {
  height: 100%;

  .pl24 {
    padding-left: 24px;
  }

  .mb48 {
    margin-bottom: 48px;
  }

  .el-table {
    .iconfont {
      font-size: 22px;
    }
  }

  .pageMain {
    margin-top: 35px;
  }

  .search {
    ::v-deep .el-input {
      width: 230px;
      height: 32px;
      margin-right: 20px;

      .el-icon-search:before {
        font-size: 22px;
        color: #2667db;
      }

      .el-input__suffix {
        right: 12px;
      }

      .el-input__inner {
        padding-right: 32px;
      }
    }
  }
}

.btn-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  // padding-right: 40px;
}

.btn-wrapper span {
  flex: 1;
  text-align: center;
}

::v-deep .el-table th.el-table__cell > .option-btn.cell {
  padding-right: 50px;
  box-sizing: border-box;
}

.submit-btn {
  text-align: center;
  display: inline-block;
  // width: 100px;
  // height: 32px;
  //  line-height: 32px;
}

.back-btn {
  text-align: center;
  display: inline-block;
  // width: 100px;
  // height: 32px;
  //  line-height: 32px;
}

::v-deep .el-table__empty-text {
  margin-top: 10%;
}

.imgver {
  width: 60px;
  height: 55px;
  margin: 0 auto;
}

::v-deep .hui1 {
  background: #091d46 !important;
}

::v-deep .lv1 {
  background: #0c265a !important;
}
</style>
